// extracted by mini-css-extract-plugin
export var bottomContent = "cosmoCarousel-module--bottomContent--4a1d6";
export var bullets = "cosmoCarousel-module--bullets--aedba";
export var carouselInterface = "cosmoCarousel-module--carouselInterface--55c14";
export var carouselWrapper = "cosmoCarousel-module--carouselWrapper--79588";
export var content = "cosmoCarousel-module--content--e3b8f";
export var cosmoCarouselWrapper = "cosmoCarousel-module--cosmoCarouselWrapper--cf493";
export var crossOut = "cosmoCarousel-module--crossOut--347b9";
export var foreground = "cosmoCarousel-module--foreground--7b0c9";
export var foregroundImage = "cosmoCarousel-module--foregroundImage--2a505";
export var foregroundInner = "cosmoCarousel-module--foregroundInner--06cd4";
export var phrase = "cosmoCarousel-module--phrase--d1ebe";
export var phraseCarousel = "cosmoCarousel-module--phraseCarousel--08b3b";
export var progressBar = "cosmoCarousel-module--progressBar--b6b19";
export var scriptType = "cosmoCarousel-module--scriptType--98c91";
export var shadowSpace = "cosmoCarousel-module--shadowSpace--d33d4";
export var topContent = "cosmoCarousel-module--topContent--bbca6";
export var videoCarousel = "cosmoCarousel-module--videoCarousel--fe09e";
export var videoSlide = "cosmoCarousel-module--videoSlide--6391e";