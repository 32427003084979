import React from "react";
import CosmoCarousel from "./CosmoCarousel";
import CosmoVideoBackground from "./CosmoVideoBackground";
import CosmoCardStack from "./CosmoCardStack";
import CosmoFullWidthAnimated from "./CosmoFullWidthAnimated";
import CosmoParallaxSymbols from "./CosmoParallaxSymbols";

// Map of listing type to listing react component.
const componentTypeMap = {
    CardStack: CosmoCardStack,
    Carousel: CosmoCarousel,
    FullWidthAnimated: CosmoFullWidthAnimated,
    ParallaxSymbols: CosmoParallaxSymbols,
    VideoBackground: CosmoVideoBackground,
};

const CosmoMain = ({ parentModule, componentId, content }) => {
    /*
    JSON object format for miscModule: 

    {
        "componentId": "VideoBackground", // Functional Component name
        "content": {
            // Whatever content you'd need inside your component comes here
        }
    }
    */
    const Component = componentTypeMap[componentId];

    return <Component {...content} />;
};

export default CosmoMain;
