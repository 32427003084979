import React, { useEffect, useRef, useCallback } from "react";
import classnames from "classnames/bind";
import * as cosmoStyles from "./cosmoVideoBackground.module.css";
import Image from "@v4/gatsby-theme-talend/src/components/Image/Image";
import gsap from "gsap";
import scrollToElement from "scroll-to-element";

// cx for CSS Modules
const cx = classnames.bind(cosmoStyles);

const CosmoVideoBackground = (props) => {
    const wrapperRef = useRef();
    const videoElRef = useRef();

    // Set up the video source URLs with transforms
    const videoBaseURL = `https://res.cloudinary.com/${process.env.GATSBY_CLOUDINARY_CLOUD_NAME}/video/upload/`;
    const videoURL = videoBaseURL + `q_auto,f_auto,ac_none/` + props.video_public_id;

    // Derive poster from props or video URL fallback (or empty)
    const posterURL = props.posterURL ?? videoURL ? `${videoURL}.webp` : "";

    // Define empty array for copy
    let copyEl = [];

    // Prep copy for output using key/value pairs for elements
    Object.entries(props.copy).forEach(([KeyEl, value], i) => {
        copyEl.push(<KeyEl key={i}>{value}</KeyEl>);
    });

    // Function to check if the video is playing
    const checkIfPlaying = useCallback((video) => {
        return video.currentTime > 0 && !video.paused && video.readyState > video.HAVE_CURRENT_DATA;
    }, []);

    useEffect(() => {
        // Funciton to toggle play state of video element based on viewport intersection
        const conditionalPlay = (entries) => {
            // If the entry is intersecting, and the video is not yet playing, play it.
            entries[0].isIntersecting && !checkIfPlaying(videoElRef.current) && videoElRef.current.play();

            // If the entry is not intersecting, and the video is playing, pause it.
            entries[0].isIntersecting === false && checkIfPlaying(videoElRef.current) && videoElRef.current.pause();
        };

        let ctx = gsap.context(() => {
            // Animation for the bouncing arrow / chevron
            gsap.to(".chevron", { y: -12, duration: 1, repeat: -1, yoyo: true });
        });

        // Options for intersection observer
        const observerOptions = {
            root: null,
            rootMargin: "0px",
            threshold: 0.2,
        };

        // Intersection observer to aid in pausing the video when it's not in view
        const observer = new IntersectionObserver(conditionalPlay, observerOptions);

        // Execute observer
        videoElRef.current && observer.observe(videoElRef.current);

        return () => {
            ctx.revert();
            observer.disconnect();
        };
    }, [checkIfPlaying]);

    return (
        <section className={cx("cosmoVideoBackground")} ref={wrapperRef}>
            <video
                autoPlay // Auto play the video
                muted // Video is muted
                loop // Loops the video
                playsInline // Video will play inline on devices
                className={`bgVideo ${cx("video")}`}
                style={{ width: "100%", objectFit: "cover" }}
                poster={posterURL}
                loading="eager"
                ref={videoElRef}
            >
                <source src={videoURL && `${videoURL}.mp4`} type="video/mp4" />
                <source src={videoURL && `${videoURL}.webm`} type="video/webm" />
                <p>Your browser does not support embedded videos.</p>
            </video>
            {copyEl.length && (
                <div className={cx("copy")}>
                    {copyEl}
                    <button
                        onClick={() => {
                            scrollToElement(wrapperRef.current, {
                                offset: wrapperRef.current.clientHeight - 100,
                                ease: "in-out-cube",
                                duration: 1000,
                            });
                        }}
                    >
                        <Image
                            className={`chevron ${cx("chevron")}`}
                            image={{ public_id: "cosmo2/Circle-Chevron-Down_2x_vw6maa", width: 26, height: 26 }}
                        ></Image>
                    </button>
                </div>
            )}
        </section>
    );
};

export default CosmoVideoBackground;
