import React, { useCallback, useEffect, useRef } from "react";
import classnames from "classnames/bind";
import * as cosmoStyles from "./cosmoCarousel.module.css";
import gsap from "gsap/all";
import Draggable from "gsap/Draggable";
import Image from "@v4/gatsby-theme-talend/src/components/Image/Image";
import { useMediaQuery } from "react-responsive";
import isDesktopQuery from "../../../utils/mediaQueries";

// cx for CSS Modules
const cx = classnames.bind(cosmoStyles);

const CosmoCarousel = (props) => {
    // Refs for querying for elements
    const wrapperRef = useRef();

    // Ref used to store the autoplay setTimeout and main timelines
    const autoplay = useRef();
    const mainTL = useRef();

    // GSAP selector utility
    const q = gsap.utils.selector(wrapperRef);

    // Seconds of time used in slide transitions
    const transTime = 0.5;

    // Amount of time to remain on each slide in seconds
    const slideDelay = 4;

    // Maximum number of slides based on props
    const maxSlides = props.slides.length;

    // The currently active slide index (not base-0)
    const activeIndex = useRef(maxSlides);

    // Media query
    const isDesktop = useMediaQuery(isDesktopQuery, undefined);

    // Define alpha value to use between desktop and mobile
    const fadedAlpha = useRef(isDesktop ? 0.33 : 0);

    // Callback (memorized) which handles slide transitions between two index values (slide numbers)
    const slideHandler = useCallback(
        (nextIndex = false) => {
            // Stop and reset the window timeout
            autoplay.current && window.clearTimeout(autoplay.current);

            // Prevent super-clicking the same slide
            if (nextIndex === activeIndex.current) return "";

            // If the next index is passed as false, use the slide after the active one as the next slide
            if (nextIndex === false) nextIndex = activeIndex.current + 1;

            // If the next index is passed as "prev", we want to go backwards in the timeline
            if (nextIndex === "prev") nextIndex = activeIndex.current - 1;

            // Capping bounds of nextIndex
            if (nextIndex > maxSlides) nextIndex = 1;
            if (nextIndex <= 0) nextIndex = maxSlides;

            // Clear out the timeline to reset any in-progress animations
            mainTL.current && mainTL.current.clear();

            // Main timeline, used for each slide transition
            mainTL.current = gsap
                .timeline()

                // Reset all bar backgrounds, then set the current bar's background
                .to(`.progressBar`, 0, { clearProps: "background" }, "0")
                .to(`.progressBar${nextIndex}`, 0, { background: "rgba(255, 255, 255, 0.5)" }, "0")

                // Reset all progress bar widths
                .to(`.progressBar span`, 0, { width: 0, autoAlpha: 0 }, "0")

                // Animate the progress bar via width
                .fromTo(
                    `.progressBar${nextIndex} span`,
                    slideDelay,
                    { autoAlpha: 1 },
                    { width: "100%", ease: "none" },
                    "0"
                )

                // Advance the media (video)
                .to(".slide", transTime, { autoAlpha: 0 }, "0")
                .fromTo(`.slide${nextIndex}`, transTime, { autoAlpha: 0 }, { autoAlpha: 1 }, "0")

                // Toggle the video playback state
                .call(
                    () => {
                        q(`.video`).forEach((video) => video.pause());
                        q(`.video${nextIndex}`).forEach((video) =>
                            video.play().catch((e) => {
                                // Error handling for autoplay
                            })
                        );
                    },
                    [],
                    "0"
                )

                // Advance the control UI (titles)
                .to(`.slideControl button`, transTime, { autoAlpha: fadedAlpha.current }, "0")
                .fromTo(
                    `.slide${nextIndex}Control button`,
                    transTime,
                    { autoAlpha: fadedAlpha.current },
                    { autoAlpha: 1 },
                    "0"
                )

                // Advance the messaging
                .to(`.slideCopy`, transTime, { autoAlpha: 0 }, "0")
                .fromTo(`.slide${nextIndex}Copy`, transTime, { autoAlpha: 0 }, { autoAlpha: 1 }, "0")

                // Change bullet styles
                .to(`.bullet`, transTime, { background: "rgba(255, 255, 255, 0)" }, "0")
                .to(`.bullet${nextIndex}`, transTime, { background: "rgba(255, 255, 255, 1)" }, "0")

                // Pause on the slide
                .to("body", { duration: slideDelay });

            // Advance the index value
            activeIndex.current = nextIndex;

            // Add new autoplay
            autoplay.current = window.setTimeout(slideHandler, slideDelay * 1000);

            // Return the timeline to animate the elements
            return mainTL.current;
        },
        [maxSlides, q]
    );

    // The primary image-shifting carousel
    const VideoCarousel = () => {
        return (
            <ul className={`videoCarousel ${cx("videoCarousel")}`}>
                {props.slides &&
                    props.slides.length &&
                    props.slides.map((slide, index) => {
                        const slideNo = index + 1;
                        const videoBaseURL = `https://res.cloudinary.com/${process.env.GATSBY_CLOUDINARY_CLOUD_NAME}/video/upload/`;
                        const videoURL = videoBaseURL + `q_auto,w_1440,f_auto,ac_none/` + slide.media.public_id;
                        return (
                            <li className={`slide slide${slideNo} ${cx("slide", "videoSlide")}`} key={slideNo}>
                                <video
                                    muted // Video is muted
                                    loop // Loopsthe video
                                    playsInline // Video will play inline on devices
                                    className={`video video${slideNo} ${cx("video")}`}
                                    poster={slide.posterURL ?? videoURL ? `${videoURL}.webp` : ""}
                                    loading="eager"
                                >
                                    <source src={videoURL && `${videoURL}.mp4`} type="video/mp4" />
                                    <source src={videoURL && `${videoURL}.webm`} type="video/webm" />
                                    <p>Your browser does not support embedded videos.</p>
                                </video>
                            </li>
                        );
                    })}
            </ul>
        );
    };

    // The carousel "controls" (titles as buttons). Desktop interface structure differs from mobile.
    const CarouselInterfaceDesktop = () => {
        return (
            <>
                <ul className={cx("carouselInterface", "carouselInterfaceMobile")}>
                    {props.slides &&
                        props.slides.length &&
                        props.slides.map((slide, index) => {
                            let slideNo = index + 1;
                            return (
                                <li className={`slideControl slide${slideNo}Control`} key={`desktopSlide-${slideNo}`}>
                                    <button
                                        className={`slideButton slide${slideNo}Button`}
                                        onClick={() => {
                                            slideHandler(slideNo);
                                        }}
                                    >
                                        {slide.phrase}
                                    </button>
                                    <div className={`progressBar progressBar${slideNo} ${cx("progressBar")}`}>
                                        <span></span>
                                    </div>
                                </li>
                            );
                        })}
                </ul>
                <ul className={cx("phraseCarousel")}>
                    {props.slides &&
                        props.slides.length &&
                        props.slides.map((slide, index) => {
                            let slideNo = index + 1;
                            return (
                                <li
                                    className={`slideCopy slide${slideNo}Copy ${cx("slideCopy", "phrase")}`}
                                    key={`desktopPhrase-${slideNo}`}
                                >
                                    {slide.tagline}
                                </li>
                            );
                        })}
                </ul>
            </>
        );
    };

    // Mobile interface structure differs from desktop
    const CarouselInterfaceMobile = () => {
        return (
            <>
                <ul className={cx("carouselInterface", "carouselInterfaceMobile")}>
                    {props.slides &&
                        props.slides.length &&
                        props.slides.map((slide, index) => {
                            let slideNo = index + 1;
                            return (
                                <li className={`slideControl slide${slideNo}Control`} key={slideNo}>
                                    <button
                                        className={`slideButton slide${slideNo}Button`}
                                        onClick={() => {
                                            slideHandler(slideNo);
                                        }}
                                    >
                                        {slide.phrase}
                                    </button>
                                    <div className={`progressBar progressBar${slideNo} ${cx("progressBar")}`}>
                                        <span></span>
                                    </div>
                                    <div className={`slideCopy slide${slideNo}Copy ${cx("slideCopy", "phrase")}`}>
                                        {slide.tagline}
                                    </div>
                                </li>
                            );
                        })}
                </ul>
            </>
        );
    };

    const CarouselBulletsMobile = () => {
        return (
            <ul className={`bullets ${cx("bullets")}`}>
                {props.slides &&
                    props.slides.length &&
                    props.slides.map((slide, index) => {
                        let slideNo = index + 1;
                        return (
                            <li
                                key={index}
                                onClick={() => {
                                    slideHandler(slideNo);
                                }}
                            >
                                <button className={`bullet${slideNo} ${cx("bullet")}`}>{slideNo}</button>
                            </li>
                        );
                    })}
            </ul>
        );
    };

    useEffect(() => {
        // Register plugins to the GSAP lib
        gsap.registerPlugin(Draggable);

        // Context variable
        let ctx = gsap.context(() => {
            // Set all "slide" elements and slide controls/copy opacity
            gsap.set(".slideButton", { autoAlpha: fadedAlpha.current });
            gsap.set(".slideCopy", { autoAlpha: 0 });

            // Init the first slide's elements
            gsap.set(".slide1Button, .slide1Copy", { autoAlpha: 1 });

            // Initialize the slideshow
            slideHandler();

            // Add draggable feature to non-desktop (mobile & device) interfaces
            if (!isDesktop) {
                // Element, outside of DOM, used as a proxy container for the Draggable object
                const proxyDiv = document.createElement("div");
                const draggable = new Draggable(proxyDiv, {
                    trigger: ".bottomContent",
                    type: "x",
                    inertia: false,
                    maxDuration: 0.75,
                    minimumMovement: 10,
                    dragClickables: true,
                    onDragEnd: (e) => {
                        if (draggable.getDirection() === "right") {
                            slideHandler();
                        } else {
                            slideHandler("prev");
                        }
                    },
                    allowContextMenu: true,
                    allowNativeTouchScrolling: true,
                    snap: {
                        x: 15,
                    },
                });
            }
        });

        return () => {
            // Revert any existing context
            ctx.revert();
            // Clear our any autoplay timeout
            window.clearTimeout(autoplay.current);
        };
    }, [slideHandler, isDesktop]);

    return (
        <div className={cx("cosmoCarouselWrapper")} ref={wrapperRef}>
            <div className={cx("foreground")}>
                <div className={cx("foregroundInner")}>
                    <div className={`topContent ${cx("topContent")}`}>
                        <h1>
                            <ins className={cx("scriptType")}>{props.copy.ins}</ins>
                            <del className={cx("crossOut")}>{props.copy.del}</del>
                            {props.copy.finisher}
                        </h1>
                    </div>
                    <div className={`shadowSpace ${cx("shadowSpace")}`}></div>
                    <div className={`bottomContent ${cx("bottomContent")}`}>
                        {isDesktop && <CarouselInterfaceDesktop />}
                        {!isDesktop && <CarouselInterfaceMobile />}
                    </div>
                    <CarouselBulletsMobile />
                </div>
                <Image
                    className={`foregroundImage ${cx("foregroundImage")}`}
                    image={{ public_id: "cosmo2/Crystal-Ball-Img_hiye3g", width: 2400, height: 1272 }}
                    imgStyle={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPostition: "center center",
                    }}
                />
                <div className={cx("carouselWrapper")}>
                    <VideoCarousel />
                </div>
            </div>
        </div>
    );
};

export default CosmoCarousel;
