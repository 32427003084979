import React, { useEffect, useRef } from "react";
import classnames from "classnames/bind";
import * as cosmoStyles from "./cosmoParallaxSymbols.module.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Image from "@v4/gatsby-theme-talend/src/components/Image/Image";
import { useMediaQuery } from "react-responsive";
import isDesktopQuery from "../../../utils/mediaQueries";

// cx for CSS Modules
const cx = classnames.bind(cosmoStyles);

const CosmoParallaxSymbols = (props) => {
    // Refs for animations
    const wrapperRef = useRef();
    const parallaxTL = useRef();

    // Define media query
    const isDesktop = useMediaQuery(isDesktopQuery);

    // Any padding on the top end as defined in the JSON content
    const topOffset = props.topOffset || "0";

    useEffect(() => {
        // Register plugins to the GSAP lib
        gsap.registerPlugin(ScrollTrigger);

        // Degrees of travel for each of the layers - the higher the number, the more the layer will move
        const parallaxQuotient = [145, 137, 130, 120];

        let ctx = gsap.context(() => {
            // Parallax effect timeline
            parallaxTL.current = gsap.timeline({
                scrollTrigger: {
                    trigger: wrapperRef.current,
                    pin: false, // pin the trigger element while active (fixed position)
                    start: "top bottom", // when the top of the trigger hits the bottom of the viewport
                    end: "+=100%", // end after scrolling % of timeline element (wrapper)
                    scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
                },
            });

            // For each symbol, add a new timeline for parallax using offset value
            props.assets.forEach((asset, i) => {
                // Establish tween
                const symbolTL = gsap.to(`.symbol${i}`, {
                    y: `-=${parallaxQuotient[asset.layer - 1]}%`,
                    ease: "none",
                });

                // Add to the main timline
                parallaxTL.current.add(symbolTL, 0);
            });
        });

        return () => {
            ctx.revert();
            parallaxTL.current.kill();
            parallaxTL.current = null;
        };
    }, [props.assets]);

    // Some of the symbol setups are a little too complex for non-desktop displays, particularly when the layout
    // has multiple interface elements, such as the carousel, or the CTAs
    if (props.hideOnMobile && !isDesktop) return "";

    return (
        <div
            className={cx("cosmoParallaxSymbols")}
            ref={wrapperRef}
            style={topOffset && { transform: `translateY(${topOffset})` }}
        >
            {props.assets.length && (
                <div className={cx("assets")}>
                    {props.assets.map((asset, i) => {
                        const imgWidth = isDesktop ? asset.width : asset.width / 2;
                        const imgHeight = isDesktop ? asset.height : asset.height / 2;

                        return (
                            <Image
                                className={`symbol${i} symbol layer${props.layerId} ${cx("symbol")}`}
                                image={{ public_id: asset.public_id, width: asset.width, height: asset.height }}
                                style={{
                                    width: `${imgWidth}px`,
                                    height: `${imgHeight}px`,
                                    [asset.position["left"] ? "left" : "right"]: asset.position[
                                        asset.position["left"] ? "left" : "right"
                                    ],
                                }}
                                imgStyle={{
                                    objectFit: "contain",
                                    position: "absolute",
                                    width: `${imgWidth}px`,
                                    height: `${imgHeight}px`,
                                    [asset.position["top"] ? "top" : "bottom"]: asset.position[
                                        asset.position["top"] ? "top" : "bottom"
                                    ],
                                }}
                                key={i}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default CosmoParallaxSymbols;
