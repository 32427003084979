import React, { useCallback, useEffect, useRef } from "react";
import classnames from "classnames/bind";
import * as cosmoStyles from "./cosmoCardStack.module.css";
import gsap from "gsap";
import Draggable from "gsap/Draggable";
import ScrollTrigger from "gsap/ScrollTrigger";
import Image from "@v4/gatsby-theme-talend/src/components/Image/Image";
import isDesktopQuery from "../../../utils/mediaQueries";
import CTA from "@v4/talend/src/components/cta/Cta";
import { useMediaQuery } from "react-responsive";

// cx for CSS Modules
const cx = classnames.bind(cosmoStyles);

const CosmoCardStack = (props) => {
    // Establish refs for animations
    const wrapperRef = useRef();

    // Master Timeline
    const masterTL = useRef();
    const sceneryTL = useRef();
    const coreTL = useRef();

    // Create an object with all of the video elements we intend to manipulate (card videos)
    const videoPlayStates = useRef({ 0: null });

    // Stores the window autoplay (if any)
    const autoplay = useRef();

    // GSAP selector utility
    const q = gsap.utils.selector(wrapperRef);

    // Adding a reload handler
    const reloadPage = () => window && window.location.reload();

    // Media query
    const isDesktop = useMediaQuery(isDesktopQuery, undefined, reloadPage);

    // Desktop TL:
    const negOffset = 400; // Distance (downward) the cards start to animate from on Desktop timeline
    const scrollDelay = 1; // "Seconds" of delay for the animation on top and bottom - translates into scroll distance

    // Mobile TL:
    let horzOffset = 110; // Horizontal distance (%) used for spacing between cards
    const vertOffset = 40; // Vertical distance (px) used for vertical positioning of center card
    const slideDelay = 4; // Amount of time to remain on each slide in seconds

    // Maximum number of cards
    const maxCards = props.cards.length;

    // The currently active card index (not base-0)
    const activeIndex = useRef(maxCards);

    const checkIfPlaying = useCallback((video) => {
        return video.currentTime > 0 && !video.paused && video.readyState > video.HAVE_CURRENT_DATA;
    }, []);

    // Mobile: Function to handle slideshow in mobile view
    const cardSlideshow = useCallback(
        (dir = "fwd") => {
            // Play all cards' videos
            q(".card .video").forEach((video) => !checkIfPlaying(video) && video.play());

            // Modifier for direction
            const directionalOffset = dir === "fwd" ? -`${horzOffset}` : horzOffset;

            // Index is non-base-0
            const currIndex = activeIndex.current;

            // Define index values
            let prevIndex = dir === "fwd" ? activeIndex.current - 1 : activeIndex.current + 1;
            let nextIndex = dir === "fwd" ? currIndex + 1 : currIndex - 1;

            // Ensure index values are not greater than the max number of cards
            if (prevIndex > maxCards) prevIndex = 1;
            if (nextIndex > maxCards) nextIndex = 1;

            // Ensure index values are not less than 1, reset to maxCards if so
            if (prevIndex < 1) prevIndex = maxCards;
            if (nextIndex < 1) nextIndex = maxCards;

            // Advance the index value
            activeIndex.current = nextIndex;

            // Stop and reset the window timeout
            autoplay.current && window.clearTimeout(autoplay.current); // disable autoplay
            autoplay.current = window.setTimeout(cardSlideshow.bind(null, dir), slideDelay * 1000); // reset autoplay

            masterTL.current = gsap
                .timeline()
                // The "previous" card slides from one frame edge, to out of frame
                .fromTo(
                    `.card${prevIndex}`,
                    { x: `${directionalOffset}%`, y: vertOffset },
                    { x: `${directionalOffset * 2}%`, y: vertOffset, duration: 0.5, ease: "power4.easeInOut" },
                    0
                )

                // The "previous" card then slides into the opposing edge of the frame
                .fromTo(
                    `.card${prevIndex}`,
                    { x: `${directionalOffset * -2}%`, y: vertOffset },
                    { x: `${directionalOffset * -1}%`, y: vertOffset, duration: 0.5, ease: "power4.easeInOut" },
                    0.5
                )

                // The current (centered) card slides to the side position
                .fromTo(
                    `.card${currIndex}`,
                    { x: 0, y: 0 },
                    { x: `${directionalOffset}%`, y: vertOffset, duration: 1, ease: "power4.easeInOut" },
                    0
                )

                // The "next" card slides to the center
                .fromTo(
                    `.card${nextIndex}`,
                    { x: `${directionalOffset * -1}%`, y: vertOffset },
                    { x: 0, y: 0, duration: 1, ease: "power4.easeInOut" },
                    0
                )

                // Hide the previous stat and headline
                .fromTo(
                    `.stat${currIndex}, .headline${currIndex}`,
                    { autoAlpha: 1 },
                    { autoAlpha: 0, duration: 1, ease: "none" },
                    0
                )

                // Show the next stat and headline
                .fromTo(
                    `.stat${nextIndex}, .headline${nextIndex}`,
                    { autoAlpha: 0 },
                    { autoAlpha: 1, duration: 1, ease: "none" },
                    0
                )

                // Add a delay
                .to("body", { duration: slideDelay }); // Add a delay

            return masterTL.current;
        },
        [maxCards, horzOffset, checkIfPlaying, q]
    );

    // Func used in the master timeline to "flip" a card over
    const cardFlip = useCallback(
        (cardIndex) => {
            // The play() method returns a promise, so we can use async functions to handle the promise
            const playVideo = async (video, cardIndex) => {
                if (!checkIfPlaying(video)) {
                    video.play();
                }
            };

            // The pause() method does not return a promise, but is rependent on the state of the promise from play()
            const pauseVideo = (video, cardIndex) => {
                if (checkIfPlaying(video)) {
                    video.pause();
                    videoPlayStates.current[cardIndex] = false;
                }
            };

            const cardClass = `.card${cardIndex}`;
            const cardEl = gsap.utils.selector(cardClass);
            const frontEl = cardEl(".cardFront");
            const frontShade = cardEl(".cardFront .shading");
            const backEl = cardEl(".cardBack");
            const backShade = cardEl(".cardBack .shading");
            const cardBorder = "0 0 0 24px white";
            const cardShadow = "0 10px 40px 30px rgba(0, 0, 0, 0.8)";

            const cardTL = gsap
                .timeline({ defaults: { ease: "none" } })
                // Scoot both sides upwards, together
                .to(cardClass, { y: 0, duration: 3 }, 0)

                // Move the front over a little while the card slides upwards
                .to(frontEl, { duration: 3 }, 0)

                .call(
                    () => {
                        // Get the video element
                        const cardVideo = cardEl(`.video`) && cardEl(`.video`)[0];

                        // If the video is not playing, play it; and vice-versa
                        if (cardVideo && videoPlayStates.current[cardIndex] === false && !checkIfPlaying(cardVideo)) {
                            // Play the video, update play value value outside of the async function
                            playVideo(cardVideo, cardIndex);
                            videoPlayStates.current[cardIndex] = true;
                        } else {
                            pauseVideo(cardVideo, cardIndex);
                        }

                        // If this is not the first card, pause the previous video
                        if (cardIndex !== 1) {
                            // Get the previous video element
                            const prevCardVideo = q(`.video${cardIndex - 1}`) && q(`.video${cardIndex - 1}`)[0];
                            // If the previous video is playing, pause it; and vice-versa
                            if (
                                prevCardVideo &&
                                videoPlayStates.current[cardIndex - 1] === true &&
                                checkIfPlaying(prevCardVideo)
                            ) {
                                // Pause the previous video
                                pauseVideo(prevCardVideo, cardIndex - 1);
                            } else {
                                // Play the previous video, update play value value outside of the async function
                                playVideo(prevCardVideo, cardIndex - 1);
                                videoPlayStates.current[cardIndex - 1] = true;
                            }
                        }
                    },
                    [],
                    3
                )

                // Change opacity of the card to 1 via autoAlpha
                .to(cardClass, { autoAlpha: 1, duration: 1 }, 0)

                // Rotate the back so it's no longer visible
                .to(backEl, { rotateY: 90, duration: 1 }, 2)
                .fromTo(backShade, { autoAlpha: 0.15 }, { autoAlpha: 1, duration: 1 }, 2)

                // and rotate the front into visibile space
                .to(
                    frontEl,
                    {
                        x: 0,
                        rotateY: 0,
                        rotateZ: `${cardIndex - 2}deg`,
                        boxShadow: `${cardBorder}, ${cardShadow}`,
                        duration: 1,
                    },
                    3
                )

                // Solve for visual collision
                .to(cardClass, { z: 0, duration: 0 }, 4)

                // Adjusting the card's shading
                .fromTo(frontShade, { autoAlpha: 0.5 }, { autoAlpha: 0.15, duration: 1 }, 3)

                // Animate the current headline, stat and bullet
                .to(`.card${cardIndex}Bullet > span`, 0.5, { background: "rgba(255, 255, 255, 1)" }, "-=0.25");

            if (cardIndex !== 1) {
                // If not the first card, hide the previous headline, stat and bullet
                cardTL
                    .to(`.card${cardIndex - 1}Bullet > span`, 0.5, { background: "rgba(255, 255, 255, 0)" }, "-=0.25")
                    .to(`.headline${cardIndex - 1}, .stat${cardIndex - 1}`, { autoAlpha: 0, duration: 1 }, 1)
                    .to(`.headline${cardIndex}, .stat${cardIndex}`, { autoAlpha: 1, duration: 1 }, 1.5);
            }

            // Return the card timeline
            return cardTL;
        },
        [q, checkIfPlaying]
    );

    // Disables any autoplay on the window from the mobile slideshow
    const disableAutoplay = useCallback(() => {
        autoplay.current && window.clearTimeout(autoplay.current);
    }, []);

    // Handle animations when browser is available
    useEffect(() => {
        // Register plugins to the GSAP lib
        gsap.registerPlugin(Draggable, ScrollTrigger);

        // Establish context
        let ctx;

        // Update the play-states variable with the number of cards/videos
        Object.keys(props.cards).forEach((i) => {
            // Non-zero card indexes
            videoPlayStates.current = { ...videoPlayStates.current, [parseInt(i) + 1]: false };
        });

        // Function to revert any/all of the animations/ScrollTriggers that were created
        const resetAnims = () => {
            // Disable any autoplay on the window object
            disableAutoplay();

            // Pause any video card elements
            q(".card .video").forEach((video) => checkIfPlaying(video) && video.pause());

            // Reset timelines
            masterTL.current && masterTL.current.kill();
            sceneryTL.current && sceneryTL.current.kill();

            // Revert any context
            ctx && ctx.revert();
        };

        // Desktop
        if (isDesktop) {
            // Revert any existing context and timeline
            resetAnims();

            // Establish a new context
            ctx = gsap.context(() => {
                // Init state: rotate card fronts, hide both sides and edges (opacity), and place below module
                gsap.set(".card", { autoAlpha: 0, y: negOffset, z: 1000 });
                gsap.set(".card1", { autoAlpha: 0, y: negOffset, z: 0 });
                gsap.set(".card .cardFront", { rotateY: -90 });

                // Set first card "bullet" styling
                gsap.set(".card1Bullet > span", { background: "rgba(255, 255, 255, 1)" });

                // Timeline to pin the scenery (galaxy background)
                sceneryTL.current = gsap.timeline({
                    scrollTrigger: {
                        trigger: wrapperRef.current,
                        pin: true, // pin the trigger element while active (fixed position)
                        start: "top -100px", // module's negative margin + nav/header height
                        scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
                        end: "+=300%", // end after scrolling % of timeline element (wrapper)
                    },
                });

                // Timeline to animate the cards -- starts earlier than the scenery timeline
                coreTL.current = gsap
                    .timeline({
                        scrollTrigger: {
                            trigger: wrapperRef.current,
                            start: "top 33%", // Y of the trigger meets Y of the viewport
                            end: "+=300%", // end after scrolling % of timeline element (wrapper)
                            scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
                        },
                    })
                    .add(cardFlip(1))
                    .to("body", { duration: scrollDelay }) // add a "delay" to the module
                    .add(cardFlip(2))
                    .to("body", { duration: scrollDelay }) // add a "delay" to the module
                    .add(cardFlip(3))
                    .to("body", { duration: scrollDelay * 2 }); // add a "delay" to the module

                masterTL.current = gsap
                    .timeline()
                    .add(sceneryTL.current)
                    .add(coreTL.current);
            });
        } else {
            // Revert any existing context and timeline
            resetAnims();

            // Establish a new context
            ctx = gsap.context(() => {
                // Equal z-index for mobile
                gsap.set(".cardFront", { zIndex: 4 });

                // Move the cards downwards
                gsap.set(".card2, .card3", { y: 40 });

                // Hide the other stats
                gsap.set(".headline2, .stat2, .headline3, .stat3", { autoAlpha: 0 });

                // Element, outside of DOM, used as a proxy container for the Draggable object
                const proxyDiv = document.createElement("div");
                const draggable = new Draggable(proxyDiv, {
                    trigger: ".cardsWrapper",
                    type: "x",
                    lockAxis: true,
                    minimumMovement: 40,
                    dragClickables: true,
                    onDragEnd: (e) => {
                        if (draggable.getDirection() === "right") {
                            cardSlideshow("rev");
                        } else {
                            cardSlideshow("fwd");
                        }
                    },
                    allowContextMenu: true,
                    allowNativeTouchScrolling: true,
                });

                // Initialize the slideshow
                cardSlideshow();
            });
        }
        return () => {
            // Revert any existing context and timeline
            resetAnims();
        };
    }, [cardFlip, cardSlideshow, isDesktop, disableAutoplay, checkIfPlaying, q, props.cards]);

    const CardBullets = () => {
        return (
            <ul className={cx("cardBullets")}>
                {props.cards &&
                    props.cards.map((card, i) => {
                        let cardNo = i + 1;
                        return (
                            <li className={`card${cardNo}Bullet cardbullet`} key={`cardBullet-${cardNo}`}>
                                <span>{card.tagline}</span>
                            </li>
                        );
                    })}
            </ul>
        );
    };

    return (
        <div className={cx("cardStackOuterPadding")} ref={wrapperRef}>
            <div className={cx("cosmoCardStackWrapper")}>
                <div className={cx("cosmoCardStack")}>
                    <div className={cx("col", "colA")}>
                        <div className={cx("content")}>
                            {props.headlines &&
                                props.headlines.map((headline, i) => {
                                    const headlineHTML = { __html: headline };
                                    return (
                                        <div
                                            className={`headline${i + 1} ${cx("headline", `headline${i + 1}`)}`}
                                            key={`headline-${i}`}
                                            dangerouslySetInnerHTML={headlineHTML}
                                        ></div>
                                    );
                                })}
                            {props.ctaURL && (
                                <CTA
                                    title={props.ctaCopy}
                                    customClass={`cardStackCTA ${cx("linkButton", "btn", "btnMobile")}`}
                                    variant="Tertiary"
                                    url={props.ctaURL}
                                />
                            )}
                        </div>
                    </div>
                    <div className={`cardsWrapper ${cx("col", "colB", "cardsWrapper")}`}>
                        <div className={`shadowCard ${cx("shadowCard")}`}></div>
                        {props.cards &&
                            props.cards.map((card, i) => {
                                const videoBaseURL = `https://res.cloudinary.com/${process.env.GATSBY_CLOUDINARY_CLOUD_NAME}/video/upload/`;
                                const videoURL = videoBaseURL + `q_auto,w_1200,f_auto,ac_none/` + card.media.public_id;
                                return (
                                    <div className={`card card${i + 1} ${cx("card", `card${i + 1}`)}`} key={`card${i}`}>
                                        <div className={`cardFront ${cx("cardFront")}`}>
                                            <div className={`shading ${cx("shading")}`}></div>
                                            <div className={cx("videoWrapper")}>
                                                <video
                                                    muted // Video is muted
                                                    loop // Loopsthe video
                                                    playsInline // Video will play inline on devices
                                                    className={`video video${i + 1} ${cx("video")}`}
                                                    poster={card.posterURL ?? videoURL ? `${videoURL}.webp` : ""}
                                                    loading="eager"
                                                    width="408"
                                                    height="637"
                                                >
                                                    <source src={videoURL && `${videoURL}.mp4`} type="video/mp4" />
                                                    <source src={videoURL && `${videoURL}.webm`} type="video/webm" />
                                                    <p>Your browser does not support embedded videos.</p>
                                                </video>
                                            </div>
                                            <div className={cx("cardPhrase")}>{card.tagline}</div>
                                        </div>
                                        <div className={`cardBack ${cx("cardBack", `card${i + 1}Back`)}`}>
                                            <div className={`shading ${cx("shading")}`}></div>
                                            <Image
                                                alt={`Card ${i + 1} Back`}
                                                image={{
                                                    public_id: "cosmo2/Tarot_Card_Back_2_zm9rnr",
                                                    width: 1080,
                                                    height: 1920,
                                                }}
                                                style={{ display: "block", width: "100%", height: "100%" }}
                                                imgStyle={{ width: "100%", height: "100%" }}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                    <div className={cx("col", "colC")}>
                        <div className={cx("content")}>
                            {props.stats &&
                                props.stats.map((stat, i) => {
                                    const statHTML = { __html: stat };
                                    return (
                                        <div
                                            className={`stat${i + 1} ${cx("stat", `stat${i + 1}`)}`}
                                            key={`stat-${i}`}
                                            dangerouslySetInnerHTML={statHTML}
                                        ></div>
                                    );
                                })}
                            {props.ctaURL && (
                                <CTA
                                    title={props.ctaCopy}
                                    customClass={cx("linkButton", "btn", "btnDesktop")}
                                    variant="Tertiary"
                                    url={props.ctaURL}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <CardBullets />
            </div>
            <Image
                className={cx("galaxyBackground")}
                image={{ public_id: props.background_public_id, width: 2880, height: 1920 }}
            ></Image>
            <div className={cx("cosmoCardStackShading")}>
                <Image
                    className={cx("glow", "blueGlow")}
                    image={{ public_id: "cosmo2/Blue-Glow_qhsvkr", width: 2040, height: 2040, format: "webp" }}
                    style={{ width: "100%", height: "100%" }}
                    imgStyle={{ width: "100%", height: "100%" }}
                    isImgTag={true}
                />
                <Image
                    className={cx("glow", "redGlow")}
                    image={{ public_id: "cosmo2/Red-Glow_dbczbe", width: 1857, height: 1857, format: "webp" }}
                    style={{ width: "100%", height: "100%" }}
                    imgStyle={{ width: "100%", height: "100%" }}
                    isImgTag={true}
                />
            </div>
        </div>
    );
};

export default CosmoCardStack;
