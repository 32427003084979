import React, { useCallback, useEffect, useRef, useState } from "react";
import gsap from "gsap";
import classnames from "classnames/bind";
import * as cosmoStyles from "./cosmoFullWidthAnimated.module.css";
import Image from "@v4/gatsby-theme-talend/src/components/Image/Image";
import Link from "@v4/gatsby-theme-talend/src/components/Link";

// cx for CSS Modules
const cx = classnames.bind(cosmoStyles);

const CosmoFullWidthAnimated = (props) => {
    // Establish refs for animations
    const wrapperRef = useRef();
    const phraseWrapper = useRef();
    const contentWrapper = useRef();
    const headline = useRef();
    const masterTl = useRef();
    const parallaxTL = useRef();
    const paxBgRef = useRef();
    const widths = useRef([]);
    const [wordWrap, setWordWrap] = useState();

    // Leverage GSAP's selector utility
    const q = gsap.utils.selector(wrapperRef);

    // Phrases to animate
    const phrases = props.copy.h1.variants;

    // Function to shift between the words in the phrase
    const wordShift = useCallback(
        (index) => {
            const transTime = 0.5;

            // "Previous' phrase (the one that's currently visible)
            const prevIndex = index - 1 < 0 ? phrases.length - 1 : index - 1;

            const phraseTL = gsap
                .timeline()
                .fromTo(`.phrase${prevIndex}`, transTime, { autoAlpha: 1 }, { autoAlpha: 0 }, "0")
                .fromTo(`.phrase${index}`, transTime, { autoAlpha: 0 }, { autoAlpha: 1 }, "0")
                .fromTo(
                    phraseWrapper.current,
                    transTime,
                    { width: widths.current[prevIndex] },
                    { width: widths.current[index] },
                    "0"
                );

            return phraseTL;
        },
        [phrases]
    );

    useEffect(() => {
        // Store initial widths in an array
        if (widths.current.length < phrases.length) {
            q(".phrase").forEach((element) => {
                widths.current.push(element.offsetWidth);
            });
        }

        // Total maximum width needed for the phrase wrapper
        const maxPhraseWidth = Math.max(...widths.current);

        // Total required width: Max phrase width plus the h1's total offset width
        const totalRequiredWidth = maxPhraseWidth + headline.current.offsetWidth;

        // Determine if we need to adjust the layout
        contentWrapper.current.offsetWidth < totalRequiredWidth ? setWordWrap("wrap") : setWordWrap("nowrap");

        let ctx = gsap.context(() => {
            // Set all variations to absolute positioning, move them to "center"
            gsap.set(".phrase", { position: "absolute", left: "50%" });

            // Define phraseWrapper's width based on first measured width
            gsap.set(phraseWrapper.current, { width: widths.current[0] });

            // Set the bgWrapper's height w/more vertical padding for parallax effect
            const paddingAmt = 20;
            gsap.set(paxBgRef.current, { height: `${100 + paddingAmt}%` });

            // Parallax effect timeline
            parallaxTL.current = gsap
                .timeline({
                    scrollTrigger: {
                        trigger: wrapperRef.current,
                        pin: false, // pin the trigger element while active (fixed position)
                        start: "top bottom", // module's negative margin + header height)
                        end: "+=100%", // end after scrolling % of timeline element (wrapper)
                        scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
                    },
                })
                // Move background upward by the padding amount
                .to(paxBgRef.current, { transform: `translateY(-${paddingAmt * 0.66}%)`, ease: "none" });

            // The total height needed for the phrase (used for wrapping)
            const phraseHeight = q(".phrase0")[0].offsetHeight;

            // Set height of phraseWrapper to the height of the first phrase
            gsap.set(phraseWrapper.current, { height: phraseHeight });

            // Timeline object
            masterTl.current = gsap.timeline({ repeat: -1 });

            // For each phrase, add a new sub-timeline to the master timeline
            Object.keys(phrases).forEach((index) => {
                // Set the width of the phrase element to the width of the phrase
                gsap.to(
                    `.phrase.phrase${index}`,
                    { transform: `translateX(-${widths.current[index] / 2}px)`, duration: 0.1 },
                    0
                );

                // Ensure the phrase is initially hidden (if not the first)
                index > 0 && gsap.set(`.phrase${index}`, { autoAlpha: 0 });

                // Use the wordShift function to add to the master timeline
                masterTl.current.add(wordShift(index)).add(gsap.to("body", { duration: 3 }));
            });
        });

        return () => {
            // Cleanup timeline and parallax
            ctx.revert();
        };
    }, [q, wordShift, phrases]);

    return (
        <div className={`cosmoFullWidthWrapper ${cx("cosmoFullWidthWrapper")}`} ref={wrapperRef}>
            <div className={cx("content")} ref={contentWrapper}>
                {props.copy.h1 && props.copy.h1.variants && (
                    <h1 className={cx("mainH1", wordWrap)} ref={headline}>
                        <span className={cx("firstPhrase")}>{props.copy.h1.part1}</span>
                        <span className={cx("phraseWrapper")} ref={phraseWrapper}>
                            {props.copy.h1.variants.map((variant, index) => (
                                <span className={`phrase phrase${index} ${cx("phrase")}`} key={`phrase-${index}`}>
                                    {variant}
                                </span>
                            ))}
                        </span>
                        <span className={cx("lastPhrase")}>{props.copy.h1.part3}</span>
                    </h1>
                )}
                {props.copy.h2 && <h2>{props.copy.h2}</h2>}
                {props.ctas && (
                    <div className={cx("ctaSection")}>
                        {props.ctas.map((cta, index) => (
                            <Link to={cta.href} className={cx("cta")} key={`cta-${index}`}>
                                {cta.copy}{" "}
                                <img
                                    src="https://res.cloudinary.com/talend/image/upload/v1665005232/cosmo2/Circle-Chevron-Right_j8r1c4.svg"
                                    width="32"
                                    height="32"
                                    alt="Chevron symbol"
                                    key={`cta-${index}`}
                                />
                            </Link>
                        ))}
                    </div>
                )}
            </div>
            <div className={`paxBgWrapper ${cx("bgWrapper")}`} ref={paxBgRef}>
                {props.background && (
                    <Image
                        className={`paxBg backgroundImage`}
                        image={{
                            public_id: props.background.public_id,
                            width: 1878,
                            height: 1069,
                        }}
                        style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                        imgStyle={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                        }}
                    ></Image>
                )}
            </div>
        </div>
    );
};

export default CosmoFullWidthAnimated;
