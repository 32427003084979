import React, { useRef } from "react";
import { graphql } from "gatsby";
import classNames from "classnames";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import formatContentfulRichText from "@v4/talend/src/utils/formatContentfulRichText";
import stylingOptions from "@v4/utils/stylingOptions";
import BugCrowdForm from "./BugCrowdForm";
import CosmoMain from "./CosmoMain";

export const fragment = graphql`
    fragment Miscellaneous on ContentfulMiscellaneous {
        id
        name
        stylingOptions {
            ...stylingOptions
        }
        heading {
            raw
        }
        body {
            raw
            references {
                ...RTEReferences
            }
        }
        headingTextShade
        component
        data {
            internal {
                content
            }
        }
    }
`;

// Map of listing type to listing react component.
const componentTypeMap = {
    "BugCrowd Form": BugCrowdForm,
    "Cosmo Component": CosmoMain,
};

const MiscModule = (props) => {
    const styleOpts = stylingOptions(props.stylingOptions, classNames);

    // Text shade options for cx classes:
    const hShade = props.headingTextShade ? "textShade" + props.headingTextShade : "";

    // Set up heading rich-text content
    const heading = props.heading && props.heading.raw ? renderRichText(props.heading, formatContentfulRichText()) : "";

    // Set up body rich-text content
    const body = props.body && props.body.raw ? renderRichText(props.body, formatContentfulRichText()) : "";

    // Get type of component.
    const Component = componentTypeMap[props.component];

    // Pass data as props.
    let data = props.data?.internal?.content;
    data = data ? JSON.parse(data) : {};

    // Pass a ref.
    const container = useRef(null);

    // Remove "framed" styling from Cosmo Components, making them full width by default"
    return props.component === "Cosmo Component" ? (
        <section id={props.moduleId} className={`${styleOpts.classString ?? ""}`} ref={container}>
            {styleOpts && styleOpts.borderTopEl}
            <Component parentModule={container} {...data} />
            {styleOpts && styleOpts.bgImageEl}
            {styleOpts && styleOpts.borderBottomEl}
        </section>
    ) : (
        <section
            id={props.moduleId}
            className={`container misc px-2 ${styleOpts.classString ?? ""}`}
            style={styleOpts.bgHex}
            ref={container}
        >
            {styleOpts && styleOpts.borderTopEl}
            <div className="heading">{heading}</div>
            <div className="body">{body}</div>
            <div className={`relative z-10 px-2 container mx-auto max-w-screen-lg ${hShade}`}>
                <Component parentModule={container} {...data} />
            </div>
            {styleOpts && styleOpts.bgImageEl}
            {styleOpts && styleOpts.borderBottomEl}
        </section>
    );
};

export default MiscModule;
