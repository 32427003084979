// extracted by mini-css-extract-plugin
export var bgWrapper = "cosmoFullWidthAnimated-module--bgWrapper--8100d";
export var content = "cosmoFullWidthAnimated-module--content--55105";
export var cosmoFullWidthWrapper = "cosmoFullWidthAnimated-module--cosmoFullWidthWrapper--43575";
export var cta = "cosmoFullWidthAnimated-module--cta--d3b43";
export var ctaSection = "cosmoFullWidthAnimated-module--ctaSection--66f23";
export var firstPhrase = "cosmoFullWidthAnimated-module--firstPhrase--182a8";
export var lastPhrase = "cosmoFullWidthAnimated-module--lastPhrase--fbf43";
export var mainH1 = "cosmoFullWidthAnimated-module--mainH1--3854c";
export var phrase = "cosmoFullWidthAnimated-module--phrase--6855a";
export var phraseWrapper = "cosmoFullWidthAnimated-module--phraseWrapper--1dca5";
export var wrap = "cosmoFullWidthAnimated-module--wrap--e1a81";