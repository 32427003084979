// extracted by mini-css-extract-plugin
export var blueGlow = "cosmoCardStack-module--blueGlow--d3b9c";
export var btn = "cosmoCardStack-module--btn--9b650";
export var btnDesktop = "cosmoCardStack-module--btnDesktop--3df43";
export var btnMobile = "cosmoCardStack-module--btnMobile--36173";
export var card = "cosmoCardStack-module--card--c04ce";
export var card1 = "cosmoCardStack-module--card1--f2d67";
export var card2 = "cosmoCardStack-module--card2--3a06b";
export var card2Back = "cosmoCardStack-module--card2Back--22840";
export var card3 = "cosmoCardStack-module--card3--a462c";
export var card3Back = "cosmoCardStack-module--card3Back--91fcc";
export var cardBack = "cosmoCardStack-module--cardBack--83d26";
export var cardBullets = "cosmoCardStack-module--cardBullets--8c671";
export var cardFront = "cosmoCardStack-module--cardFront--98303";
export var cardPhrase = "cosmoCardStack-module--cardPhrase--beb37";
export var cardStackOuterPadding = "cosmoCardStack-module--cardStackOuterPadding--5588f";
export var colA = "cosmoCardStack-module--colA--eab2e";
export var colB = "cosmoCardStack-module--colB--57a25";
export var colC = "cosmoCardStack-module--colC--ecdb9";
export var content = "cosmoCardStack-module--content--4d116";
export var cosmoCardStack = "cosmoCardStack-module--cosmoCardStack--55329";
export var cosmoCardStackShading = "cosmoCardStack-module--cosmoCardStackShading--1f267";
export var cosmoCardStackWrapper = "cosmoCardStack-module--cosmoCardStackWrapper--5f887";
export var galaxyBackground = "cosmoCardStack-module--galaxyBackground--c57a9";
export var glow = "cosmoCardStack-module--glow--6f38e";
export var headline = "cosmoCardStack-module--headline--9136f";
export var headline1 = "cosmoCardStack-module--headline1--bba77";
export var linkButton = "cosmoCardStack-module--linkButton--48f30";
export var redGlow = "cosmoCardStack-module--redGlow--bc8c0";
export var shading = "cosmoCardStack-module--shading--c57cf";
export var shadowCard = "cosmoCardStack-module--shadowCard--afc5d";
export var stat = "cosmoCardStack-module--stat--e630e";
export var stat1 = "cosmoCardStack-module--stat1--f7b0b";
export var video = "cosmoCardStack-module--video--2acff";
export var videoWrapper = "cosmoCardStack-module--videoWrapper--b4953";